@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:100,200,300,400,500,600,700,900&display=swap");
html {
  scroll-behavior: smooth;
}
body {
  font-family: "Source Sans Pro", sans-serif;
}

/* theme Styles */

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #ff9900;
}

::placeholder {
  color: #a9b1b7 !important;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1280px;
  }
}

.btn-primary {
  color: #fff !important;
  background-color: #ff9900;
  border-color: #ff9900 !important;
  width: 245px;
  font-size: 20px;
  box-shadow: -1px 1px 20px rgba(0, 0, 0, 0.22);
}
.btn-primary:hover {
  background: #f29c1a;
  border-color: #f29c1a;
}

.hvr-underline-from-center:before {
  background: #ff9900;
  height: 2.5px;
}

.bg-light {
  background-color: #dfedfb !important;
}

/* Header */

.header_brand_search {
  max-width: 600px;
}
.header_search {
  width: 360px;
}

.navbar {
  padding-top: 0;
  padding-bottom: 0;
  transition: 0.3s ease all;
  z-index: 99999999;
}
.navbar-brand img {
  max-width: 145px;
}
.navbar-brand span {
  color: white;
  display: inline-block;
  padding-left: 5px;
  transition: 0.3s ease all;
}
.navbar-light .navbar-nav .nav-link {
  color: rgb(255, 255, 255);
  font-weight: 600;
  font-size: 17px;
  text-transform: uppercase;
  padding: 5px 4px;
  margin: 10px 20px;
  transition: 0.3s ease all;
}
.navbar-light .navbar-nav .nav-link:hover {
  color: #f29c1a;
  transition: 0.3s ease all;
}
.nav-border-left {
  border-left: 1px solid rgba(255, 255, 255, 0.51);
}
.navbar-light .navbar-nav .nav-link.btn.btn-primary {
  padding: 7px 15px;
  margin: 8px 3px;
  width: auto;
}
.navbar-light .navbar-nav .nav-link.btn.btn-primary:hover {
  box-shadow: -1px 1px 20px rgba(0, 0, 0, 0.2);
}

.header_search .btn.btn-primary {
  width: auto;
  font-size: 1rem !important;
  box-shadow: unset !important;
}

/* Affix CSS */
.navbar.affix {
  background: white !important;
  transition: 0.3s ease all;
  box-shadow: 0 0 9px rgba(0, 0, 0, 0.15);
}
.affix.navbar-light .navbar-nav .nav-link {
  color: black;
}
.affix.navbar-light .navbar-nav .nav-link:hover {
  color: #f29c1a;
}
.affix.navbar-light .navbar-brand span {
  color: #000;
  transition: 0.3s ease all;
}
.affix.navbar-light .nav-border-left {
  border-left: 1px solid rgba(0, 0, 0, 0.11);
}

.navbar_mt {
  display: none;
}

/* Mobile Menu */
.navbar_mt {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 10px 20px;
  z-index: 99999999;
  transition: 0.3s ease all;
}

.navbar_brand_c {
  padding-left: 10px;
  transition: 0.3s ease all;
}
.nmt_bar {
  color: white;
  background: transparent;
  border: 0;
  font-size: 27px;
  transition: 0.3s ease all;
}
.affixmobile {
  background: white;
  transition: 0.3s ease all;
}
.affixmobile .nmt_bar {
  color: #000;
  transition: 0.3s ease all;
}
.affixmobile .navbar-brand span {
  color: black;
  transition: 0.3s ease all;
}

.navbar_mt_collapse {
  width: 320px;
  background: white;
  position: fixed;
  left: -120%;
  top: 0;
  height: 100%;
  z-index: 99999999999;
  padding: 15px;
  transition: 0.5s ease all;
}
.navbar_mt_collapsed {
  left: 0;
  transition: 0.5s ease all;
}
.navbar_brand_clps {
}

.navbar_mt_close {
  text-align: right;
  position: absolute;
  top: 0;
  right: 0;
}
.navbar_mt_close button {
  color: black;
  background: transparent;
  border: 0;
  font-size: 22px;
}
.cloned_ul {
}
.navbar-brand span {
  font-size: 16px;
}
.navbar-brand img {
  max-width: 24px;
}
.nmt_bar {
  font-size: 22px;
}

.nmt_navbar_fh {
  height: 100%;
  position: relative;
}

.nav-item-primary a {
  width: 100%;
}
.navbar_mt_logo {
  position: absolute;
  left: 0;
  top: 0;
}
.navbar_mt_logo span {
  color: black;
}
/* Main Section */

.mainsection {
  padding: 60px 0;
  min-height: 700px;
  background-size: cover !important;
  background-position: top left !important;
}
.main_content {
  padding-top: 120px;
  color: white;
  text-align: center;
}

.main_content h1 {
  font-size: 63px;
  margin-bottom: 30px;
}
.main_content h4 {
  font-size: 31px;
  max-width: 600px;
  margin: auto;
  margin-bottom: 20px;
}
.main_cta {
}
.main_cta a {
}

/* Property CSS */

section.propertieshome {
  padding: 25px 0;
}
div#propertiesslider {
  margin-top: -190px;
}
.psh_slider_div {
  background: white;
  box-shadow: 0px 3px 20px rgba(100, 100, 100, 0.24);
  margin: 20px 0;
  border-radius: 7px;
  overflow: hidden;
  color: black;
  display: block;
  transition: 0.3s ease all;
}
.psh_slider_div:hover {
  text-decoration: none;
  color: black;
  transition: 0.3s ease all;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.35);
}

.psh_slider_div:hover img {
  transform: scale(1.1);
  transition: 0.5s ease all;
}

.psh_header {
  position: relative;
}

.psh_img {
  height: 215px;
  overflow: hidden;
}
.psh_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -o-object-fit: cover;
  transition: 0.5s ease all;
  transform: scale(1);
}
.psh_price_mi {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-right: 40px;
  padding-left: 10px;
  padding-bottom: 10px;
  padding-top: 49px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.71), transparent);
  color: white;
}
.psh_price h5 {
  font-size: 29px;
  margin-bottom: 3px;
}
.psh_up_info {
}
.psh_up_info ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.psh_up_info ul li {
  display: inline-block;
  padding-right: 10px;
  border-right: 1px solid white;
  margin-right: 11px;
}
.psh_up_info ul li:last-child {
  margin-right: 0;
  padding-right: 0;
  border: 0;
}
.psh_heart {
  position: absolute;
  right: 10px;
  bottom: 7px;
  font-size: 22px;
}
.psh_heart button {
  background: transparent;
  border: 0;
  padding: 0;
  color: white;
}

.psh_property_details {
}
.psh_property_details ul {
  list-style: none;
  margin: 0;
  border-bottom: 1.5px solid #dfedfb;
  padding: 15px 15px;
}
.psh_property_details ul li {
  border-right: 1.5px solid #dfedfb;
  padding-right: 15px;
  margin-right: 15px;
}
.psh_property_details ul li h6 {
  font-size: 12px;
  margin: 0;
}
.psh_property_details ul li:nth-child(1) {
  -ms-flex: 0 0 30%;
  flex: 0 0 30%;
  max-width: 30%;
}
.psh_property_details ul li:nth-child(2) {
  -ms-flex: 0 0 30%;
  flex: 0 0 30%;
  max-width: 30%;
  padding: 0;
}
.psh_property_details ul li:nth-child(3) {
  -ms-flex: 0 0 40%;
  flex: 0 0 40%;
  max-width: 40%;
  margin-right: 0;
  border: 0;
  padding-right: 0;
}
.psh_property_details ul li p {
  font-size: 20px;
  margin: 0;
}
.psh_property_details ul li p sub {
  font-size: 11px;
  bottom: 0;
}
.psh_property_details ul li p sup {
  font-size: 13px;
}

.psh_address p {
  margin: 0;
  font-size: 15px;
  word-break: break-all;
}
.psh_footer {
  padding: 10px 15px;
}
.psh_neightbourhood_info h6 {
  margin: 0;
  font-size: 14px;
}
.psh_neightbourhood_info h6 {
  margin: 0;
  font-size: 14px;
}
.psh_neightbourhood_info p {
  margin: 0;
  color: #ff9900;
  font-size: 15px;
}

.ph_cta {
  text-align: center;
  padding: 30px 0;
}

.psh_footer .row {
  margin: 0 -5px;
}
.psh_footer .row .col-7,
.psh_footer .row .col-5 {
  padding: 0 5px;
}

/* Simple Fast Smart */
.title {
  text-align: center;
  margin-bottom: 15px;
}
.title h2 {
  font-size: 39px;
  margin: 0;
}
.title p {
  font-size: 24px;
  margin: 0;
}
.sfs_section {
  padding: 0px 0 100px 0;
}

.sfs_main {
  background: white;
  border-radius: 8px;
  overflow: hidden;
  margin: 15px 0;
}
.sfs_main:hover img {
  transform: scale(1.1);
  transition: 0.5s ease all;
}
.sfs_img {
  height: 230px;
  overflow: hidden;
}
.sfs_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -o-object-fit: cover;
  transition: 0.5s ease all;
}
.sfs_content {
  padding: 25px 25px;
  text-align: center;
}
.sfs_content h3 {
  font-size: 21px;
}
.sfs_content p {
}
.sfs_content a {
  width: auto;
  padding: 5px 25px;
  box-shadow: unset;
}

/*footer*/

.footer_section_1 {
  padding: 40px 0;
}

.social_ul li {
  display: inline-block;

  padding: 0 5px;
}
.social_ul li a {
  font-size: 20px !important;
}
.fs1 {
}
.fs1 h4 {
  font-size: 19px;
  margin-bottom: 5px;
}
.fs1 ul {
  margin: 0;
  list-style: none;
  padding: 0 2px;
}
.ftr_row {
  margin: 0 -2px;
}
.fs1 ul li {
  line-height: 19px;
}
.fs1 ul li a {
  color: #939aa0;
  font-size: 13px;
  display: inline-block;
}
.fs1 ul li a:hover {
  color: #ff9900;
}

.flex_w50 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.ftr_more a {
  color: #3a9fd1;
}

.footer_section_2 {
  background: #e1e5e8;
  padding: 30px 0;
}
.footer_section_2 p {
  color: #939aa0;
  margin: 0;
  text-align: center;
  padding-right: 120px;
  position: relative;
}
.footer_section_2 p img {
  max-width: 45px;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.3;
}
.footer_section_2 a {
  color: #3a9fd1;
}
